export const OPEN_LOGIN_MODAL = "OPEN_LOGIN_MODAL";
export const CLOSE_LOGIN_MODAL = "CLOSE_LOGIN_MODAL";

export const OPEN_REGISTER_MODAL = "OPEN_REGISTER_MODAL";
export const CLOSE_REGISTER_MODAL = "CLOSE_REGISTER_MODAL";

export const OPEN_FORGOT_PASSWORD_MODAL = "OPEN_FORGOT_PASSWORD_MODAL";
export const CLOSE_FORGOT_PASSWORD_MODAL = "CLOSE_FORGOT_PASSWORD_MODAL";

export const OPEN_MENU_HEADER = "OPEN_MENU_HEADER";
export const CLOSE_MENU_HEADER = "CLOSE_MENU_HEADER";

export const OPEN_ALERT = "OPEN_ALERT";
export const CLOSE_ALERT = "CLOSE_ALERT";
