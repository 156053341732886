import { ThunkAction, ThunkDispatch } from "redux-thunk";

import {
    REGISTER_USER,
    MANAGE_ERRORS,
    IS_LOADING,
    LOGOUT,
    LOGIN,
    FORGOT_PASSWORD,
    UPDATE_PICTURE,
    UPDATE_USER_INFO,
} from "./names/user";
import {
    registerUser,
    manageErrors,
    iterateObjInput,
    isLoadingType,
    loginType,
    forgotPasswordType,
    updatePictureType,
    updateUserInfoType,
} from "../types/user";
import axios from "../../axios";
import ErrorMessage from "../../models/errorMessage";
import { onCloseRegisterModal, onCloseLoginModal, onOpenAlert } from "./app";
import { resetDevice } from "./device";

type actionCombineForGetInfo = loginType | any;
type actionCombine = registerUser | manageErrors | isLoadingType | any;
let timer: any;

export const isLoading = (loadingState: boolean): isLoadingType => {
    return {
        type: IS_LOADING,
        state: loadingState,
    };
};

export const resetErrors = (): manageErrors => {
    return {
        type: MANAGE_ERRORS,
        data: {
            message: "",
        },
    };
};

export const register = (
    first_name: string,
    last_name: string,
    email: string,
    password: string
): ThunkAction<Promise<void>, {}, {}, registerUser> => {
    return async (
        dispatch: ThunkDispatch<{}, {}, actionCombine>,
        getState: any
    ): Promise<void> => {
        if (getState().user.errors.message !== "") {
            dispatch({
                type: MANAGE_ERRORS,
                data: {
                    message: "",
                },
            });
        }

        dispatch({
            type: IS_LOADING,
            state: true,
        });

        try {
            const response = await axios.post("/register", {
                first_name,
                last_name,
                email,
                password,
            });

            const resData = await response.data.message;

            const authToken = `${resData.token_type} ${resData.access_token}`;
            axios.defaults.headers.common["Authorization"] = authToken;

            const responseUser = await axios.get(
                "/me?picture=t&extra=t&timezone=t"
            );

            const resUserData = await responseUser.data.message;

            const jsonData: any = {
                id: resUserData.id,
                first_name,
                last_name,
                email,
                password,
                api_access: resUserData.api_access,
                picture: resUserData.picture,
            };

            dispatch(
                authenticate(
                    jsonData.id,
                    authToken,
                    parseInt(resData.expires_in) * 1000
                )
            );

            dispatch({
                type: REGISTER_USER,
                ...jsonData,
            });

            dispatch(onCloseRegisterModal());
        } catch (err) {
            if (err.response && err.response.data.message && err.response.status) {
                if (err.response.status === 400) {
                    const errorData = err.response.data.message;
                    dispatch({
                        type: MANAGE_ERRORS,
                        data: {
                            message:
                                "Revisa los campos. No podemos enviar tus datos.",
                            inputs: createInputErrors(errorData),
                        },
                    });
                } else {
                    const errorMessage = ErrorMessage.getMessage(
                        err.response.data.message
                    );
                    dispatch({
                        type: MANAGE_ERRORS,
                        data: {
                            message: errorMessage,
                        },
                    });
                }
            } else {
                dispatch({
                    type: MANAGE_ERRORS,
                    data: {
                        message: err.message,
                    },
                });
            }
        }

        dispatch({
            type: IS_LOADING,
            state: false,
        });
    };
};

export const login = (
    email: string,
    password: string
): ThunkAction<Promise<void>, {}, {}, registerUser> => {
    return async (
        dispatch: ThunkDispatch<{}, {}, actionCombine>,
        getState: any
    ): Promise<void> => {
        if (getState().user.errors.message !== "") {
            dispatch({
                type: MANAGE_ERRORS,
                data: {
                    message: "",
                },
            });
        }

        dispatch({
            type: IS_LOADING,
            state: true,
        });

        try {
            const response = await axios.post("/login", {
                email,
                password,
            });

            const resData = await response.data.message;

            const authToken = `${resData.token_type} ${resData.access_token}`;
            axios.defaults.headers.common["Authorization"] = authToken;

            const responseUser = await axios.get(
                "/me?picture=t&extra=t&timezone=t"
            );

            const resUserData = await responseUser.data.message;

            const jsonData: any = {
                id: resUserData.id,
                first_name: resUserData.first_name,
                last_name: resUserData.last_name,
                email,
                api_access: resUserData.api_access,
                picture: resUserData.picture,
                birth_day: resUserData.birth_day,
                birth_month: resUserData.birth_month,
                birth_year: resUserData.birth_year,
                username: resUserData.username,
                about: resUserData.about,
                timezone: resUserData.timezone,
            };

            dispatch(
                authenticate(
                    jsonData.id,
                    authToken,
                    parseInt(resData.expires_in) * 1000
                )
            );

            dispatch({
                type: LOGIN,
                ...jsonData,
            });

            dispatch(onCloseLoginModal());
        } catch (err) {
            if (err.response && err.response.data.message && err.response.status) {
                if (err.response.status === 400) {
                    const errorData = err.response.data.message;
                    dispatch({
                        type: MANAGE_ERRORS,
                        data: {
                            message:
                                "Revisa los campos. No podemos enviar tus datos.",
                            inputs: createInputErrors(errorData),
                        },
                    });
                } else {
                    const errorMessage = ErrorMessage.getMessage(
                        err.response.data.message
                    );
                    dispatch({
                        type: MANAGE_ERRORS,
                        data: {
                            message: errorMessage,
                        },
                    });
                }
            } else {
                dispatch({
                    type: MANAGE_ERRORS,
                    data: {
                        message: err.message,
                    },
                });
            }
        }

        dispatch({
            type: IS_LOADING,
            state: false,
        });
    };
};

export const updatePicture = (
    file: File
): ThunkAction<Promise<void>, {}, {}, updatePictureType> => {
    return async (
        dispatch: ThunkDispatch<
            {},
            {},
            updatePictureType | isLoadingType | manageErrors
        >
    ): Promise<void> => {
        dispatch({
            type: IS_LOADING,
            state: true,
        });

        try {
            var formData = new FormData();
            formData.append("picture", file);

            const response = await axios.post("/me", formData);

            const resData = await response.data.message;

            dispatch({
                type: UPDATE_PICTURE,
                picture: resData.picture,
            });

            dispatch(
                onOpenAlert(
                    "Imagen correctamente actualizada",
                    "success"
                ) as any
            );
        } catch (err) {
            if (err.response && err.response.data.message && err.response.status) {
                if (err.response.status === 400) {
                    const errorData = err.response.data.message;
                    let messageInfo = "";
                    for (const keyErrorData in errorData) {
                        if (errorData.hasOwnProperty(keyErrorData)) {
                            const errorDataResponse = errorData[keyErrorData];
                            messageInfo += errorDataResponse + ", ";
                        }
                    }
                    dispatch({
                        type: MANAGE_ERRORS,
                        data: {
                            message: messageInfo,
                            inputs: createInputErrors(errorData),
                        },
                    });
                } else {
                    const errorMessage = ErrorMessage.getMessage(
                        err.response.data.message
                    );
                    dispatch({
                        type: MANAGE_ERRORS,
                        data: {
                            message: errorMessage,
                        },
                    });
                }
            } else {
                dispatch({
                    type: MANAGE_ERRORS,
                    data: {
                        message: err.message,
                    },
                });
            }
        }

        dispatch({
            type: IS_LOADING,
            state: false,
        });
    };
};

export const updateUserInfo = (data: {
    first_name?: string | null | undefined;
    last_name?: string | null | undefined;
    username?: string | null | undefined;
    birth_day?: number | null | undefined;
    birth_month?: number | null | undefined;
    birth_year?: number | null | undefined;
    about?: string | null | undefined;
}): ThunkAction<Promise<void>, {}, {}, updateUserInfoType> => {
    return async (
        dispatch: ThunkDispatch<
            {},
            {},
            updateUserInfoType | isLoadingType | manageErrors
        >
    ): Promise<void> => {
        dispatch({
            type: IS_LOADING,
            state: true,
        });

        try {
            const response = await axios.post("/me", data);

            const resData = await response.data.message;

            dispatch({
                type: UPDATE_USER_INFO,
                ...resData,
            });

            dispatch(
                onOpenAlert(
                    "Información correctamente actualizada",
                    "success"
                ) as any
            );
        } catch (err) {
            if (err.response && err.response.data.message && err.response.status) {
                if (err.response.status === 400) {
                    const errorData = err.response.data.message;
                    dispatch({
                        type: MANAGE_ERRORS,
                        data: {
                            message:
                                "Revisa los campos. No podemos enviar tus datos.",
                            inputs: createInputErrors(errorData),
                        },
                    });
                } else {
                    const errorMessage = ErrorMessage.getMessage(
                        err.response.data.message
                    );
                    dispatch({
                        type: MANAGE_ERRORS,
                        data: {
                            message: errorMessage,
                        },
                    });
                }
            } else {
                dispatch({
                    type: MANAGE_ERRORS,
                    data: {
                        message: err.message,
                    },
                });
            }
        }

        dispatch({
            type: IS_LOADING,
            state: false,
        });
    };
};

export const getUsersInfo = () => {
    return async (
        dispatch: ThunkDispatch<{}, {}, actionCombineForGetInfo>
    ): Promise<void> => {
        let dataUser = null;
        if (localStorage.getItem("userData") !== null) {
            dataUser = JSON.parse(localStorage.getItem("userData")!);
        }

        if (dataUser) {
            try {
                const responseUser = await axios.get(
                    "/me?picture=t&extra=t&timezone=t"
                );

                const resUserData = await responseUser.data.message;

                const jsonData: any = {
                    id: resUserData.id,
                    first_name: resUserData.first_name,
                    last_name: resUserData.last_name,
                    email: resUserData.email,
                    api_access: resUserData.api_access,
                    picture: resUserData.picture,
                    birth_day: resUserData.birth_day,
                    birth_month: resUserData.birth_month,
                    birth_year: resUserData.birth_year,
                    username: resUserData.username,
                    about: resUserData.about,
                    timezone: resUserData.timezone,
                };

                dispatch(
                    authenticate(
                        jsonData.id,
                        null,
                        parseInt(dataUser.userExpiration) * 1000
                    )
                );

                dispatch({
                    type: LOGIN,
                    ...jsonData,
                });
            } catch (err) {
                dispatch(logout());
            }
        } else {
            dispatch(logout());
        }
    };
};

export const forgotPassword = (email: string) => {
    return async (
        dispatch: ThunkDispatch<{}, {}, forgotPasswordType>
    ): Promise<void> => {
        try {
            await axios.post("/api/public/forgot/password", { email });

            dispatch({
                type: FORGOT_PASSWORD,
            });
        } catch (err) {}
    };
};

export const logout = () => {
    return (dispatch: ThunkDispatch<{}, {}, any>) => {
        clearLogoutTimer();
        localStorage.removeItem("userData");
        axios.defaults.headers.common["Authorization"] = null;
        dispatch(resetDevice());
        dispatch({ type: LOGOUT });
    };
};

const createInputErrors = (data: any): iterateObjInput => {
    const data_inputs: { [key: string]: any } = {};

    for (const errorKey in data) {
        if (data.hasOwnProperty(errorKey)) {
            const errorValue = data[errorKey];
            data_inputs[errorKey] = {
                state: true,
                message: errorValue.join(" "),
            };
        }
    }

    return data_inputs as iterateObjInput;
};

const authenticate = (
    userId: number,
    token: string | null,
    expirationTime: number
) => {
    if (localStorage.getItem("userData") === null) {
        localStorage.setItem(
            "userData",
            JSON.stringify({
                userToken: token,
                userExpiration: expirationTime,
                userId: userId,
            })
        );
    }

    if (expirationTime > 2147483647) expirationTime = 2147483647;

    return (dispatch: ThunkDispatch<{}, {}, any>) => {
        dispatch(setLogoutTimer(expirationTime));
    };
};

const setLogoutTimer = (expirationTime: number) => {
    return (dispatch: ThunkDispatch<{}, {}, any>) => {
        timer = setTimeout(() => {
            dispatch(logout());
        }, expirationTime);
    };
};

const clearLogoutTimer = () => {
    if (timer) {
        clearTimeout(timer);
    }
};
