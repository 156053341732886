import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        footerContainer: {
            paddingRight: "15px",
            paddingLeft: "15px",
            marginRight: "auto",
            marginLeft: "auto",
        },        
        footerListInline: {
            display: "block",
            marginTop: 0,
            marginBottom: "3rem !important",
            paddingLeft: 0,
            listStyle: "none",
            marginBlockStart: "1em",
            marginBlockEnd: "1em",
            marginInlineStart: "0px",
            marginInlineEnd: "0px",
            "& li": {
                listStyle: "none"
            }
        },
        footerListItem: {
            display: "inline-block",
            "&:not(:last-child)": {
                marginRight: ".5rem"
            }
        },        
        footerSocialLink: {
            display: "block",
            height: "4rem",
            width: "4rem",
            lineHeight: "4.5rem",
            fontSize: "1.5rem",
            backgroundColor: "#2aabe4",
            transition: "background-color .15s ease-in-out",
            boxShadow: "0 3px 3px 0 rgba(0,0,0,.1)",
            color: "#fff !important",
            marginRight: "1rem !important",
            borderRadius: "50% !important",
            textDecoration: "none",
            cursor: "pointer",
            "&:hover": {
                backgroundColor: "#1d809f",
                textDecoration: "none"
            },
            "& svg": {
                color: "#fff !important"
            }
        },
        footerCr: {
            color: "#6c757d !important",
            marginBottom: "0 !important",
            marginTop: 0,
            fontSize: "80%",
            fontWeight: 400
        }
    })
);

export default useStyles;