export default class ErrorMessage {
    static getMessage(response: any) {
        if (response.data && response.data.message) {
            return response.data.message;
        } else if (response.data && response.data.error) {
            return response.data.error;
        } else {
            if (typeof response.data === "string") {
                return response.data;
            } else {
                if (response.error &&  response.error === "Unauthorized") {
                    return "Por favor revisa tu usuario y contraseña";
                } else {
                    return "Por favor contactanos para reportar este error.";
                }
            }
        }
    }
}
