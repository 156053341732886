import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
    Drawer,
    List,
    Divider,
    Typography,
    Avatar,
    Collapse,
} from "@material-ui/core";
import {
    WebAsset as WebAssetIcon,
    Settings as SettingsIcon,
    Dashboard as DashboardIcon,
    SettingsInputAntenna as SettingsInputAntennaIcon,
    Person as PersonIcon,
    ExitToApp as ExitToAppIcon,
    Close as CloseIcon,
    Accessibility as AccessibilityIcon,
} from "@material-ui/icons";

import MenuListItem from "./MenuListItem";
import useStyles from "../styles/HeaderMenu";
import * as appActions from "../store/actions/app";
import * as userActions from "../store/actions/user";
import { RootStateType as stateAppType } from "../store/reducers/main";

export const HeaderMenuItemsGuest: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    return (
        <List>
            <MenuListItem
                onClick={() => {
                    history.push("/iot");
                    dispatch(appActions.onCloseMenuHeader());
                }}
                itemIcon={<SettingsInputAntennaIcon />}
                itemText="Internet de las cosas"
                selected={useRouteMatch("/iot") ? true : false}
            />
            <MenuListItem
                onClick={() => {
                    dispatch(appActions.onOpenRegisterModal());
                    dispatch(appActions.onCloseMenuHeader());
                }}
                itemIcon={<PersonIcon />}
                itemText="Registro"
            />
            <MenuListItem
                onClick={() => {
                    dispatch(appActions.onOpenLoginModal());
                    dispatch(appActions.onCloseMenuHeader());
                }}
                itemIcon={<AccessibilityIcon />}
                itemText="Iniciar sesión"
            />
            <Divider />
            <MenuListItem
                onClick={() => {
                    dispatch(appActions.onCloseMenuHeader());
                }}
                itemIcon={<CloseIcon />}
                itemText="Cerrar Menú"
            />
        </List>
    );
};

export const HeaderMenuItemsAuth: React.FC<{
    apiAccess?: Date | undefined | null;
}> = (props) => {
    const { apiAccess } = props;
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const stateUserInfo = useSelector((state: stateAppType) => state.user);
    const routeMatchDashboardApi = useRouteMatch("/dashboard/api");
    const [showIoTSubMenu, setShowIoTSubMenu] = useState<boolean>(false);
    const iotSubMenu = useRouteMatch("/dashboard/iot");
    useEffect(() => {
        if (iotSubMenu) {
            setShowIoTSubMenu(true);
        } else {
            setShowIoTSubMenu(false);
        }
    }, [iotSubMenu]);

    return (
        <List>
            <Avatar
                alt={stateUserInfo.username + "-picture"}
                src={stateUserInfo.picture ? stateUserInfo.picture : ""}
                className={classes.avatarStyle}
            />

            <Typography variant="h5" className={classes.name}>
                {stateUserInfo.first_name}
            </Typography>
            <Divider />
            <MenuListItem
                onClick={() => {
                    history.push("/iot");
                    dispatch(appActions.onCloseMenuHeader());
                }}
                itemIcon={<SettingsInputAntennaIcon />}
                itemText="Internet de las cosas"
                selected={useRouteMatch("/iot") ? true : false}
            />
            <MenuListItem
                onClick={() => {
                    history.push("/dashboard/iot");
                    dispatch(appActions.onCloseMenuHeader());
                }}
                itemIcon={<DashboardIcon />}
                itemText="Mis dispositivos IoT"
                selected={
                    useRouteMatch("/dashboard/iot")?.isExact ? true : false
                }
            />
            <Collapse in={showIoTSubMenu}>
                <MenuListItem
                    onClick={() => {
                        history.push("/dashboard/iot/gps");
                        dispatch(appActions.onCloseMenuHeader());
                    }}
                    itemText="GPS"
                    selected={
                        useRouteMatch("/dashboard/iot/gps") ? true : false
                    }
                />
                <MenuListItem
                    onClick={() => {}}
                    itemText="On/Off"
                    disabled={true}
                />
                <MenuListItem
                    onClick={() => {}}
                    itemText="Temperatura"
                    disabled={true}
                />
                <MenuListItem
                    onClick={() => {}}
                    itemText="Humedad"
                    disabled={true}
                />
            </Collapse>
            {apiAccess ? (
                <MenuListItem
                    onClick={() => {
                        history.push("/dashboard/api");
                        dispatch(appActions.onCloseMenuHeader());
                    }}
                    itemIcon={<WebAssetIcon />}
                    itemText="API"
                    selected={routeMatchDashboardApi ? true : false}
                />
            ) : null}
            <Divider />
            <MenuListItem
                onClick={() => {
                    history.push("/dashboard/settings");
                    dispatch(appActions.onCloseMenuHeader());
                }}
                itemIcon={<SettingsIcon />}
                itemText="Ajustes"
                selected={useRouteMatch("/dashboard/settings") ? true : false}
            />
            <MenuListItem
                onClick={() => {
                    dispatch(userActions.logout());
                    dispatch(appActions.onCloseMenuHeader());
                    history.push("/iot");
                }}
                itemIcon={<ExitToAppIcon />}
                itemText="Cerrar sesión"
            />
            <MenuListItem
                onClick={() => {
                    dispatch(appActions.onCloseMenuHeader());
                }}
                itemIcon={<CloseIcon />}
                itemText="Cerrar Menú"
            />
        </List>
    );
};

interface ComponentProps {
    open: boolean;
}

const HeaderMenu: React.FC<ComponentProps> = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const stateUserId = useSelector((state: stateAppType) => state.user.id);

    return (
        <Drawer
            anchor="right"
            open={props.open}
            onClose={() => {
                dispatch(appActions.onCloseMenuHeader());
            }}
        >
            <div className={classes.menuListStyle}>
                {stateUserId !== 0 ? (
                    <HeaderMenuItemsAuth />
                ) : (
                    <HeaderMenuItemsGuest />
                )}
            </div>
        </Drawer>
    );
};

export default HeaderMenu;
