import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        menuListStyle: {
            width: "250px",
        },
        avatarStyle: {
            margin: "15px auto",
            width: theme.spacing(7),
            height: theme.spacing(7),
        },
        name: {
            textAlign: "center",
            marginBottom: "11px",
        },
    })
);

export default useStyles;
