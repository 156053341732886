import { ThunkAction, ThunkDispatch } from "redux-thunk";

import {
    CREATE_DEVICE,
    DELETE_DEVICE,
    FETCH_DEVICES,
    GET_DEVICE_TOKEN,
    IS_DEVICE_LOADING,
    MANAGE_DEVICE_ERRORS,
    RESET_DEVICE,
} from "./names/device";
import {
    createDeviceType,
    deleteDeviceType,
    fetchDevicesType,
    isLoadingType,
    getDeviceTokenType,
    iterateObjInputType,
    manageErrorsType,
    resetDeviceType,
} from "../types/device";
import axios from "../../axios";
import ErrorMessage from "../../models/errorMessage";
import { onOpenAlert } from "./app";

export const resetDevice = (): resetDeviceType => {
    return {
        type: RESET_DEVICE,
    };
};

export const resetErrors = (): manageErrorsType => {
    return {
        type: MANAGE_DEVICE_ERRORS,
        data: {
            message: "",
        },
    };
};

export const fetchDevices = (): ThunkAction<
    Promise<void>,
    {},
    {},
    fetchDevicesType
> => {
    return async (
        dispatch: ThunkDispatch<{}, {}, fetchDevicesType | isLoadingType>,
        getState: any
    ): Promise<void> => {
        if (!getState().device.succesFetchDevices) {
            dispatch({
                type: IS_DEVICE_LOADING,
                state: true,
            });

            try {
                const response = await axios.get("/iot/device");
                const resData = await response.data.message;
                const newResponse = resData[0];
                const devicesData = resData[0].data;
                delete newResponse.data;
                const updatedResponse = {
                    ...newResponse,
                    devices: devicesData.reverse(),
                };
                dispatch({
                    type: FETCH_DEVICES,
                    data: updatedResponse,
                });
            } catch (err) {
                dispatch(
                    onOpenAlert(
                        "No pudimos obtener los dispositivos",
                        "warning"
                    ) as any
                );
            }

            dispatch({
                type: IS_DEVICE_LOADING,
                state: false,
            });
        }
    };
};

export const createDevice = (
    data: createDeviceType
): ThunkAction<Promise<void>, {}, {}, createDeviceType> => {
    return async (
        dispatch: ThunkDispatch<
            {},
            {},
            createDeviceType | isLoadingType | manageErrorsType
        >
    ): Promise<void> => {
        dispatch({
            type: IS_DEVICE_LOADING,
            state: true,
        });

        try {
            const response = await axios.post("/iot/device", {
                category_name: data.category_name,
                name: data.name,
            });
            const resData = await response.data.message;
            dispatch({
                type: CREATE_DEVICE,
                data: resData,
            });

            dispatch(
                onOpenAlert(
                    "Dispositivo exitosamente creado.",
                    "success"
                ) as any
            );
        } catch (err) {
            let flagAlert = false;
            if (err.response && err.response.data.message && err.response.status) {
                if (err.response.status === 400) {
                    const errorData = err.response.data.message;
                    dispatch({
                        type: MANAGE_DEVICE_ERRORS,
                        data: {
                            message:
                                "Revisa los campos. No podemos enviar tus datos.",
                            inputs: createInputErrors(errorData),
                        },
                    });
                } else {
                    const errorMessage = ErrorMessage.getMessage(
                        err.response.data.message
                    );
                    dispatch({
                        type: MANAGE_DEVICE_ERRORS,
                        data: {
                            message: errorMessage,
                        },
                    });
                    flagAlert = true;
                }
            } else {
                dispatch({
                    type: MANAGE_DEVICE_ERRORS,
                    data: {
                        message: err.message,
                    },
                });
                flagAlert = true;
            }

            if (flagAlert) {
                dispatch(
                    onOpenAlert(
                        "No pudimos crear el dispositivo. Intenta después.",
                        "error"
                    ) as any
                );
            }
        }

        dispatch({
            type: IS_DEVICE_LOADING,
            state: false,
        });
    };
};

export const deleteDevice = (
    deviceId: string | number
): ThunkAction<Promise<void>, {}, {}, deleteDeviceType> => {
    return async (
        dispatch: ThunkDispatch<
            {},
            {},
            deleteDeviceType | isLoadingType | manageErrorsType
        >
    ): Promise<void> => {
        dispatch({
            type: IS_DEVICE_LOADING,
            state: true,
        });

        try {
            await axios.delete("/iot/device/" + deviceId);
            dispatch({
                type: DELETE_DEVICE,
                id: deviceId,
            });

            dispatch(
                onOpenAlert(
                    "Dispositivo exitosamente eliminado.",
                    "success"
                ) as any
            );
        } catch (err) {
            let flagAlert = false;
            if (err.response && err.response.data.message && err.response.status) {
                if (err.response.status === 400) {
                    const errorData = err.response.data.message;
                    dispatch({
                        type: MANAGE_DEVICE_ERRORS,
                        data: {
                            message:
                                "Revisa los campos. No podemos enviar tus datos.",
                            inputs: createInputErrors(errorData),
                        },
                    });
                } else {
                    const errorMessage = ErrorMessage.getMessage(
                        err.response.data.message
                    );
                    dispatch({
                        type: MANAGE_DEVICE_ERRORS,
                        data: {
                            message: errorMessage,
                        },
                    });
                    flagAlert = true;
                }
            } else {
                dispatch({
                    type: MANAGE_DEVICE_ERRORS,
                    data: {
                        message: err.message,
                    },
                });
                flagAlert = true;
            }

            if (flagAlert) {
                dispatch(
                    onOpenAlert(
                        "No pudimos eliminar el dispositivo. Intenta después.",
                        "error"
                    ) as any
                );
            }
        }

        dispatch({
            type: IS_DEVICE_LOADING,
            state: false,
        });
    };
};

export const getDeviceToken = (
    deviceId: string | number
): ThunkAction<Promise<void>, {}, {}, getDeviceTokenType> => {
    return async (
        dispatch: ThunkDispatch<
            {},
            {},
            getDeviceTokenType | isLoadingType | manageErrorsType
        >
    ): Promise<void> => {
        dispatch({
            type: IS_DEVICE_LOADING,
            state: true,
        });

        try {
            const response = await axios.post("/iot/device/token/" + deviceId);
            const resData = await response.data.message;

            dispatch({
                type: GET_DEVICE_TOKEN,
                id: deviceId,
                token: resData
            });

            dispatch(
                onOpenAlert(
                    "Token creado exitosamente.",
                    "success"
                ) as any
            );
        } catch (err) {
            let flagAlert = false;
            if (err.response && err.response.data.message && err.response.status) {
                if (err.response.status === 400) {
                    const errorData = err.response.data.message;
                    dispatch({
                        type: MANAGE_DEVICE_ERRORS,
                        data: {
                            message:
                                "Revisa los campos. No podemos enviar tus datos.",
                            inputs: createInputErrors(errorData),
                        },
                    });
                } else {
                    const errorMessage = ErrorMessage.getMessage(
                        err.response.data.message
                    );
                    dispatch({
                        type: MANAGE_DEVICE_ERRORS,
                        data: {
                            message: errorMessage,
                        },
                    });
                    flagAlert = true;
                }
            } else {
                dispatch({
                    type: MANAGE_DEVICE_ERRORS,
                    data: {
                        message: err.message,
                    },
                });
                flagAlert = true;
            }

            if (flagAlert) {
                dispatch(
                    onOpenAlert(
                        "No pudimos crear el token. Intenta después.",
                        "error"
                    ) as any
                );
            }
        }

        dispatch({
            type: IS_DEVICE_LOADING,
            state: false,
        });
    };
};

const createInputErrors = (data: any): iterateObjInputType => {
    const data_inputs: { [key: string]: any } = {};

    for (const errorKey in data) {
        if (data.hasOwnProperty(errorKey)) {
            const errorValue = data[errorKey];
            data_inputs[errorKey] = {
                state: true,
                message: errorValue.join(" "),
            };
        }
    }

    return data_inputs as iterateObjInputType;
};
