import React from "react";
import { useRouteMatch } from "react-router-dom";
import { Typography } from "@material-ui/core";
import {
    Facebook as FacebookIcon,
    Twitter as TwitterIcon,
    YouTube as YouTubeIcon,
} from "@material-ui/icons";

import useStyles from "../styles/Footer";

const Footer: React.FC = () => {
    const classes = useStyles();
    const matchIndex = useRouteMatch("/");
    const matchDashboard = useRouteMatch("/dashboard");
    if (
        matchIndex?.isExact ||
        matchDashboard
    ) {
        return null;
    }

    const dt = new Date();

    return (
        <footer>
            <div className={classes.footerContainer}>
                <ul className={classes.footerListInline}>
                    <li className={classes.footerListItem}>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.facebook.com/hadaelectronics/"
                            className={classes.footerSocialLink}
                        >
                            <FacebookIcon />
                        </a>
                    </li>

                    <li className={classes.footerListItem}>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://twitter.com/hadaelectronics/"
                            className={classes.footerSocialLink}
                        >
                            <TwitterIcon />
                        </a>
                    </li>

                    <li className={classes.footerListItem}>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.youtube.com/channel/UC9lcDcT4O8j_YIHaqC9DmJw"
                            className={classes.footerSocialLink}
                        >
                            <YouTubeIcon />
                        </a>
                    </li>
                </ul>

                <Typography className={classes.footerCr}>
                    Todos los derechos reservados &copy; Hada Electronics 2016 -{" "}
                    {dt.getFullYear()}
                </Typography>
            </div>
        </footer>
    );
};

export default Footer;
