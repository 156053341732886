import {
    REGISTER_USER,
    MANAGE_ERRORS,
    IS_LOADING,
    LOGOUT,
    LOGIN,
    FORGOT_PASSWORD,
    UPDATE_PICTURE,
    UPDATE_USER_INFO,
} from "../actions/names/user";
import {
    AppState,
    ActionTypes,
    registerUser,
    manageErrors,
    isLoadingType,
    logoutType,
    loginType,
    updatePictureType,
    updateUserInfoType,
} from "../types/user";

const initialState: AppState = {
    id: 0,
    first_name: "",
    last_name: "",
    email: "",
    api_access: null,
    picture: null,
    birth_day: 0,
    birth_month: 0,
    birth_year: 0,
    username: "",
    about: null,
    timezone: "",
    errors: {
        message: "",
    },
    isLoading: false,
};

const logout = (state: AppState, action: logoutType): AppState => {
    return initialState;
};

const isLoadingHandler = (state: AppState, action: isLoadingType): AppState => {
    return {
        ...state,
        isLoading: action.state,
    };
};

const registerUserHandler = (
    state: AppState,
    action: registerUser
): AppState => {
    if (action.id) {
        return {
            ...state,
            id: action.id,
            first_name: action.first_name,
            last_name: action.last_name,
            email: action.email,
            api_access: action.api_access,
            picture: action.picture,
            birth_day: action.birth_day,
            birth_month: action.birth_month,
            birth_year: action.birth_year,
            username: action.username,
            about: action.about,
            timezone: action.timezone,
        };
    }
    return {
        ...state,
        first_name: action.first_name,
        last_name: action.last_name,
        email: action.email,
    };
};

const login = (state: AppState, action: loginType): AppState => {
    return {
        ...state,
        id: action.id,
        first_name: action.first_name,
        last_name: action.last_name,
        email: action.email,
        api_access: action.api_access,
        picture: action.picture,
        birth_day: action.birth_day,
        birth_month: action.birth_month,
        birth_year: action.birth_year,
        username: action.username,
        about: action.about,
        timezone: action.timezone,
    };
};

const manageErrorsHandler = (
    state: AppState,
    action: manageErrors
): AppState => {
    if (action.data.inputs) {
        return {
            ...state,
            errors: {
                message: action.data.message,
                inputs: action.data.inputs,
            },
        };
    }
    return {
        ...state,
        errors: {
            message: action.data.message,
        },
    };
};

const updatePictureHandler = (state: AppState, action: updatePictureType) => {
    return {
        ...state,
        picture: action.picture,
    };
};

const updateUserInfoHandler = (state: AppState, action: updateUserInfoType) => {
    type iterativeObj = { [key: string]: string | boolean };
    const newAction: iterativeObj = action as unknown as iterativeObj;
    var prop = "type";
    delete newAction[prop];
    return {
        ...state,
        ...newAction,
    };
};

export default (state = initialState, action: ActionTypes) => {
    switch (action.type) {
        case REGISTER_USER:
            return registerUserHandler(state, action);
        case MANAGE_ERRORS:
            return manageErrorsHandler(state, action);
        case IS_LOADING:
            return isLoadingHandler(state, action);
        case LOGOUT:
            return logout(state, action);
        case LOGIN:
            return login(state, action);
        case FORGOT_PASSWORD:
            return state;
        case UPDATE_PICTURE:
            return updatePictureHandler(state, action);
        case UPDATE_USER_INFO:
            return updateUserInfoHandler(state, action);
        default:
            return state;
    }
};
