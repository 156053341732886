import React from "react";
import { Backdrop, CircularProgress } from "@material-ui/core";

const Loader: React.FC = () => {
    return (
        <Backdrop style={{ zIndex: 1000 }} open={true}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
};

export default Loader;
