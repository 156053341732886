import React, { Suspense, lazy, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";

import Loader from "./components/Loader";
import * as userActions from "./store/actions/user";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Alert from "./components/Alert";

const DashboardApp = lazy(() => {
    return import("./views/Dashboard/App");
});

const WelcomeView = lazy(() => {
    return import("./views/Welcome");
});

const WelcomeIoTView = lazy(() => {
    return import("./views/WelcomeIoT");
});

const PrivacityView = lazy(() => {
    return import("./views/Privacity");
});

const LoginModal = lazy(() => {
    return import("./components/modals/Login");
});

const RegisterModal = lazy(() => {
    return import("./components/modals/Join");
});

const ForgotPasswordModal = lazy(() => {
    return import("./components/modals/ForgotPassword");
});

const ChangePasswordModal = lazy(() => {
    return import("./components/modals/ChangePassword");
});

const App: React.FC = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(userActions.getUsersInfo());
    }, [dispatch]);

    return (
        <div>
            <Header />
            <Suspense fallback={<Loader />}>
                <Switch>
                    <Route path="/dashboard" component={DashboardApp} />

                    <Route path="/iot" exact component={WelcomeIoTView} />

                    <Route path="/privacity" exact component={PrivacityView} />

                    <Route path="/" exact component={WelcomeView} />

                    <Route>
                        <Redirect to="/" />
                    </Route>
                </Switch>

                <Alert />
                <LoginModal />
                <RegisterModal />
                <ForgotPasswordModal />
                <ChangePasswordModal />
            </Suspense>
            <Footer />
        </div>
    );
};

export default App;
