import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import ReduxThunk from "redux-thunk";

import theme from "./styles/main";
import App from "./App";
import "./index.css";

// Reducers
import { rootReducer } from "./store/reducers/main";

declare global {
    interface Window {
      __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// const store = createStore(rootReducer, composeEnhancers(applyMiddleware(ReduxThunk)));

const store = createStore(rootReducer, applyMiddleware(ReduxThunk));

export function ReactApp() {
    return (
        // <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
        // </React.StrictMode>
    );
}

const StyleApp = (
    <ThemeProvider theme={theme}>
        <ReactApp />
    </ThemeProvider>
);

ReactDOM.render(StyleApp, document.getElementById("root"));
