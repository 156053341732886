import axios from "axios";

const urlApi = process.env.REACT_APP_API_URL;
const urlSite = process.env.REACT_APP_SITE_URL;

const axiosInstance = axios.create({
    baseURL: urlApi,
});

type localStorageObjType = {
    userToken: string | null;
    userExpiration: string | null;
    userId: string | null;
};

let dataUser: localStorageObjType = {
    userToken: null,
    userExpiration: null,
    userId: null,
};
if (localStorage.getItem("userData") !== null) {
    dataUser = JSON.parse(localStorage.getItem("userData")!);
}

axiosInstance.defaults.headers.common["Authorization"] = dataUser.userToken;
axiosInstance.defaults.headers.common["Content-Type"] = "application/json";
axiosInstance.defaults.headers.common["Access-Control-Allow-Origin"] = urlSite;

// axiosInstance.interceptors.request.use(request => {
//     // console.log(request);
//     // Edit request config
//     return request;
// }, error => {
//     // console.log(error);
//     return Promise.reject(error);
// });

// axiosInstance.interceptors.response.use(response => {
//     // console.log(response);
//     // Edit response config
//     return response;
// }, error => {
//     // console.log(error);
//     return Promise.reject(error);
// });

export default axiosInstance;
