import {
    OPEN_LOGIN_MODAL,
    CLOSE_LOGIN_MODAL,
    OPEN_REGISTER_MODAL,
    CLOSE_REGISTER_MODAL,
    OPEN_MENU_HEADER,
    CLOSE_MENU_HEADER,
    OPEN_FORGOT_PASSWORD_MODAL,
    CLOSE_FORGOT_PASSWORD_MODAL,
    CLOSE_ALERT,
    OPEN_ALERT,
} from "../actions/names/app";
import { AppState, ActionTypes, openAlertType } from "../types/app";

const initialState: AppState = {
    isLoginModalOpen: false,
    isRegisterModalOpen: false,
    isHeaderMenuOpen: false,
    isForgetPasswordModalOpen: false,
    alert: {
        isOpen: false,
        text: "",
        typeName: undefined,
    },
};

const openLoginModalHandler = (state: AppState): AppState => {
    return {
        ...state,
        isLoginModalOpen: true,
        isRegisterModalOpen: false,
    };
};

const closeLoginModalHandler = (state: AppState): AppState => {
    return {
        ...state,
        isLoginModalOpen: false,
        isRegisterModalOpen: false,
    };
};

const openRegisterModalHandler = (state: AppState): AppState => {
    return {
        ...state,
        isLoginModalOpen: false,
        isRegisterModalOpen: true,
    };
};

const closeRegisterModalHandler = (state: AppState): AppState => {
    return {
        ...state,
        isLoginModalOpen: false,
        isRegisterModalOpen: false,
    };
};

const openMenuHeaderHandler = (state: AppState): AppState => {
    return {
        ...state,
        isHeaderMenuOpen: true,
    };
};

const closeMenuHeaderHandler = (state: AppState): AppState => {
    return {
        ...state,
        isHeaderMenuOpen: false,
    };
};

const openForgotPasswordModalHandler = (state: AppState): AppState => {
    return {
        ...state,
        isForgetPasswordModalOpen: true,
    };
};

const closeForgotPasswordModalHandler = (state: AppState): AppState => {
    return {
        ...state,
        isForgetPasswordModalOpen: false,
    };
};

const openAlertHandler = (state: AppState, action: openAlertType): AppState => {
    return {
        ...state,
        alert: {
            isOpen: true,
            text: action.text,
            typeName: action.typeName,
        },
    };
};

const closeAlertHandler = (state: AppState): AppState => {
    return {
        ...state,
        alert: {
            isOpen: false,
            text: "",
            typeName: undefined,
        },
    };
};

export default (state = initialState, action: ActionTypes) => {
    switch (action.type) {
        case OPEN_LOGIN_MODAL:
            return openLoginModalHandler(state);
        case CLOSE_LOGIN_MODAL:
            return closeLoginModalHandler(state);
        case OPEN_REGISTER_MODAL:
            return openRegisterModalHandler(state);
        case CLOSE_REGISTER_MODAL:
            return closeRegisterModalHandler(state);
        case OPEN_MENU_HEADER:
            return openMenuHeaderHandler(state);
        case CLOSE_MENU_HEADER:
            return closeMenuHeaderHandler(state);
        case OPEN_FORGOT_PASSWORD_MODAL:
            return openForgotPasswordModalHandler(state);
        case CLOSE_FORGOT_PASSWORD_MODAL:
            return closeForgotPasswordModalHandler(state);
        case OPEN_ALERT:
            return openAlertHandler(state, action);
        case CLOSE_ALERT:
            return closeAlertHandler(state);
        default:
            return state;
    }
};
