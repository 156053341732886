import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { AppBar, Toolbar, Typography, IconButton } from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";

import HeaderMenu from "./HeaderMenu";
import { RootStateType as stateAppType } from "../store/reducers/main";
import * as appActions from "../store/actions/app";

const Header: React.FC = () => {
    const matchIndex = useRouteMatch("/");
    const dispatch = useDispatch();
    const stateDrawer = useSelector(
        (state: stateAppType) => state.app.isHeaderMenuOpen
    );

    if (matchIndex!.isExact) {
        return null;
    }

    const openSideMenu = (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === "keydown" &&
            ((event as React.KeyboardEvent).key === "Tab" ||
                (event as React.KeyboardEvent).key === "Shift")
        )
            return;

        if (stateDrawer) {
            dispatch(appActions.onCloseMenuHeader());
        } else {
            dispatch(appActions.onOpenMenuHeader());
        }
    };

    return (
        <div style={{ flexGrow: 1 }}>
            <AppBar position="relative">
                <Toolbar>
                    <Typography variant="h6" style={{ flexGrow: 1 }}>
                        Hada Electronics
                    </Typography>

                    <IconButton
                        edge="end"
                        color="inherit"
                        aria-label="menu"
                        onClick={openSideMenu}
                    >
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>

            <HeaderMenu open={stateDrawer} />
        </div>
    );
};

export default Header;
