import { combineReducers } from "redux";

import appReducer from "../reducers/app";
import userReducer from "../reducers/user";
import deviceReducer from "../reducers/device";

export const rootReducer = combineReducers({
    app: appReducer,
    user: userReducer,
    device: deviceReducer,
});

export type RootStateType = ReturnType<typeof rootReducer>;
