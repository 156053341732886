import {
    OPEN_LOGIN_MODAL,
    CLOSE_LOGIN_MODAL,
    OPEN_REGISTER_MODAL,
    CLOSE_REGISTER_MODAL,
    OPEN_MENU_HEADER,
    CLOSE_MENU_HEADER,
    OPEN_FORGOT_PASSWORD_MODAL,
    CLOSE_FORGOT_PASSWORD_MODAL,
    OPEN_ALERT,
    CLOSE_ALERT,
} from "./names/app";

import {
    openLoginModal,
    closeLoginModal,
    openRegisterModal,
    closeRegisterModal,
    openMenuHeaderType,
    closeMenuHeaderType,
    openForgetPasswordModalType,
    closeForgetPasswordModalType,
    openAlertType,
    closeAlertType,
} from "../types/app";

export const onOpenLoginModal = (): openLoginModal => {
    return { type: OPEN_LOGIN_MODAL };
};

export const onCloseLoginModal = (): closeLoginModal => {
    return { type: CLOSE_LOGIN_MODAL };
};

export const onOpenRegisterModal = (): openRegisterModal => {
    return { type: OPEN_REGISTER_MODAL };
};

export const onCloseRegisterModal = (): closeRegisterModal => {
    return { type: CLOSE_REGISTER_MODAL };
};

export const onOpenMenuHeader = (): openMenuHeaderType => {
    return { type: OPEN_MENU_HEADER };
};

export const onCloseMenuHeader = (): closeMenuHeaderType => {
    return { type: CLOSE_MENU_HEADER };
};

export const onOpenForgetPasswordModal = (): openForgetPasswordModalType => {
    return { type: OPEN_FORGOT_PASSWORD_MODAL };
};

export const onCloseForgetPasswordModal = (): closeForgetPasswordModalType => {
    return { type: CLOSE_FORGOT_PASSWORD_MODAL };
};

export const onOpenAlert = (
    text: string,
    typeName: "error" | "success" | "info" | "warning" | undefined
): openAlertType => {
    return { type: OPEN_ALERT, text, typeName };
};

export const onCloseAlert = (): closeAlertType => {
    return { type: CLOSE_ALERT };
};
