import {
    DELETE_DEVICE,
    FETCH_DEVICES,
    CREATE_DEVICE,
    GET_DEVICE_TOKEN,
    RESET_DEVICE,
} from "../actions/names/device";
import {
    ActionTypes,
    AppStateType,
    fetchDevicesType,
    createDeviceType,
    deleteDeviceType,
    getDeviceTokenType,
} from "../types/device";

const initialState: AppStateType = {
    data: {
        devices: [],
    },
    succesFetchDevices: false,
    errors: {
        message: "",
    },
    isLoading: false,
    count_gps: 0,
    count_temp: 0,
    count_io: 0,
    count_humedity: 0,
    count_total: 0,
};

const fetchDevicesHandler = (state: AppStateType, action: fetchDevicesType) => {
    const countGps = action.data.devices.filter(
        (device) => device.category === "geometry"
    ).length;
    const countTemp = action.data.devices.filter(
        (device) => device.category === "temperature"
    ).length;
    const countIO = action.data.devices.filter(
        (device) => device.category === "on_off"
    ).length;
    const countHumedity = action.data.devices.filter(
        (device) => device.category === "humedity"
    ).length;
    const countTotal = action.data.devices.length;

    return {
        ...state,
        data: action.data,
        succesFetchDevices: true,
        count_gps: countGps,
        count_temp: countTemp,
        count_io: countIO,
        count_humedity: countHumedity,
        count_total: countTotal,
    };
};

const createDeviceHandler = (state: AppStateType, action: createDeviceType) => {
    const newDevice = action.data;
    const oldDevices = state.data.devices;
    const updatedDevices = [...oldDevices.reverse(), newDevice];

    const countGps = updatedDevices.filter(
        (device) => device!.category === "geometry"
    ).length;
    const countTemp = updatedDevices.filter(
        (device) => device!.category === "temperature"
    ).length;
    const countIO = updatedDevices.filter(
        (device) => device!.category === "on_off"
    ).length;
    const countHumedity = updatedDevices.filter(
        (device) => device!.category === "humedity"
    ).length;
    const countTotal = updatedDevices.length;

    return {
        ...state,
        data: {
            ...state.data,
            devices: updatedDevices.reverse(),
        },
        count_gps: countGps,
        count_temp: countTemp,
        count_io: countIO,
        count_humedity: countHumedity,
        count_total: countTotal,
    };
};

const deleteDeviceHandler = (state: AppStateType, action: deleteDeviceType) => {
    const currDevices = state.data.devices;
    const indexDevice = currDevices
        .map((device) => device.id)
        .indexOf(action.id);
    if (indexDevice > -1) {
        currDevices.splice(indexDevice, 1);
    }

    const countGps = currDevices.filter(
        (device) => device!.category === "geometry"
    ).length;
    const countTemp = currDevices.filter(
        (device) => device!.category === "temperature"
    ).length;
    const countIO = currDevices.filter(
        (device) => device!.category === "on_off"
    ).length;
    const countHumedity = currDevices.filter(
        (device) => device!.category === "humedity"
    ).length;
    const countTotal = currDevices.length;

    return {
        ...state,
        data: {
            ...state.data,
            devices: currDevices.sort(),
        },
        count_gps: countGps,
        count_temp: countTemp,
        count_io: countIO,
        count_humedity: countHumedity,
        count_total: countTotal,
    };
};

const getDeviceTokenHandler = (
    state: AppStateType,
    action: getDeviceTokenType
) => {
    const currDevices = state.data.devices;
    const indexDevice = currDevices
        .map((device) => device.id)
        .indexOf(action.id);

    if (indexDevice > -1) {
        const deviceUpdated = {
            ...currDevices[indexDevice],
            token: action.token
        }
        
        const devicesUpdated: any[] = [];
        for (let i = 0; i < currDevices.length; i++) {
            const eleDevice = currDevices[i];
            if (i === indexDevice) {
                devicesUpdated.push(deviceUpdated);
            } else {
                devicesUpdated.push(eleDevice);
            }
        }

        return {
            ...state,
            data: {
                ...state.data,
                devices: devicesUpdated,
            }
        };
    } else {
        return state;
    }
};

export default (state = initialState, action: ActionTypes) => {
    switch (action.type) {
        case FETCH_DEVICES:
            return fetchDevicesHandler(state, action) as AppStateType;
        case CREATE_DEVICE:
            return (createDeviceHandler(
                state,
                action
            ) as unknown) as AppStateType;
        case DELETE_DEVICE:
            return (deleteDeviceHandler(
                state,
                action
            ) as unknown) as AppStateType;
        case GET_DEVICE_TOKEN:
            return (getDeviceTokenHandler(
                state,
                action
            ) as unknown) as AppStateType;
        case RESET_DEVICE: 
            return initialState;
        default:
            return state as AppStateType;
    }
};
