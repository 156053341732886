import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Snackbar } from "@material-ui/core";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";

import { RootStateType as stateAppType } from "../store/reducers/main";
import * as appActions from "../store/actions/app";

const ContainerAlert: React.FC<AlertProps> = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const Alert: React.FC = () => {
    const dispatch = useDispatch();
    const alertState = useSelector(
        (state: stateAppType) => state.app.alert
    );

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === "clickaway") {
            return;
        }

        dispatch(appActions.onCloseAlert());
    };

    return (
        <Snackbar
            open={alertState.isOpen}
            autoHideDuration={6000}
            onClose={handleClose}
        >
            <ContainerAlert onClose={handleClose} severity={alertState.typeName}>
                {alertState.text}
            </ContainerAlert>
        </Snackbar>
    );
};

export default Alert;
