import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
    typography: {
        fontFamily: 'Dosis,sans-serif',
        subtitle2: {
            fontSize: 12,
            fontWeight: 600,
            fontStyle: 'italic',
            color: 'red',
            textTransform: "uppercase",
            textAlign: "center",
        },
    },
    palette: {
        primary: {
            main: '#2aabe4',
        },
        secondary: {
            main: '#ffcd24',
        }
    },
    overrides: {
        MuiButton: {
          root: {
            fontWeight: "bold",
            textTransform: "none"
          }
        }
    }
});