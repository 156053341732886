import React, { ReactNode } from "react";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";

interface ComponentProps {
    onClick: (event: React.MouseEvent<HTMLElement>) => any;
    itemIcon?: ReactNode;
    itemText: string;
    selected?: boolean;
    disabled?: boolean;
    align?: "left" | "right" | "center" | undefined;
}

const MenuListItem: React.FC<ComponentProps> = (props) => {
    return (
        <ListItem
            button
            style={{textAlign: props.align ? props.align : "left"}}
            disabled={props.disabled ? props.disabled : false}
            onClick={(event) => props.onClick(event)}
            selected={props.selected ? props.selected : false}
        >
            {props.itemIcon ? (
                <ListItemIcon>{props.itemIcon}</ListItemIcon>
            ) : null}

            <ListItemText primary={props.itemText} />
        </ListItem>
    );
};

export default MenuListItem;
